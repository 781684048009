import axios from 'axios'
import config from '../config'
import interceptorRequest from '@/services/interceptors/request.js'
import interceptorResponseSuccess from '@/services/interceptors/response-success.js'
import interceptorResponseError from '@/services/interceptors/response-error.js'
import getAuthToken from '@/helpers/aad.js'

export const source = axios.CancelToken.source()

export const getApi = (options = {}) => {
  const http = axios.create({
    baseURL: `${config.apiUrl}`,
    withCredentials: true,
    ...options,
  })

  http.interceptors.request.use(async (config) => {
    const authToken = await getAuthToken()

    if (authToken) {
      config.headers.Authorization = authToken
    }

    return config
  })

  http.interceptors.request.use(interceptorRequest)
  http.interceptors.response.use(interceptorResponseSuccess, interceptorResponseError)

  return http
}

// Option to authorize http auth if needed
export const authorizeHttp = () => {}

export const api = {
  query(resource, params) {
    return getApi().get(resource, params)
  },
  get(resource, slug = '') {
    return getApi().get(`${resource}/${slug}`)
  },
  post(resource, params) {
    return getApi().post(`${resource}`, params)
  },
  update(resource, slug, params) {
    return getApi().put(`${resource}/${slug}`, params)
  },
  put(resource, params) {
    return getApi().put(`${resource}`, params)
  },
  download(resource, params, filename) {
    return getApi()
      .post(`${resource}`, params, {
        responseType: 'blob', // important
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
  },
}
